// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


var language = localStorage.getItem("saved_language") || "kor"




document.addEventListener("turbolinks:load", () => {
    language_display(language)

    
    const btnNavEl = document.querySelector(".btn-mobile-nav");
    const headerEl = document.querySelector(".header");
    
    btnNavEl.addEventListener("click", function () {
        headerEl.classList.toggle("nav-open");
    });

});



window.toggle_lan = function () {
    language = language === "kor" ? "eng" : "kor"
    language_display(language)
    localStorage.setItem("saved_language", language)
}

function language_display(language) {
    var eng = document.getElementsByClassName("desc-english");
    var kor = document.getElementsByClassName("desc-korean");
    eng = Array.from(eng);
    kor = Array.from(kor);

    if (language === "eng") {
        eng.map(show);
        kor.map(hide);           
    }
    else {
        eng.map(hide)
        kor.map(show)
    }
}


function show(lan) {
    return (lan.style.display = "block");
}

function hide(lan) {
    return (lan.style.display = "none");
}




// Make mobile navigation work


///////////////////////////////////////////////////////////
// Smooth scrolling animation

// const allLinks = document.querySelectorAll("a:link");

// allLinks.forEach(function (link) {
//   link.addEventListener("click", function (e) {
//     e.preventDefault();
//     const href = link.getAttribute("href");

//     // Scroll back to top
//     if (href === "#")
//       window.scrollTo({
//         top: 0,
//         behavior: "smooth"
//       });

//     // Scroll to other links
//     if (href !== "#" && href.startsWith("#")) {
//       const sectionEl = document.querySelector(href);
//       sectionEl.scrollIntoView({ behavior: "smooth" });
//     }

//     // Close mobile naviagtion
//     if (link.classList.contains("main-nav-link"))
//       headerEl.classList.toggle("nav-open");
//   });
// });

// ///////////////////////////////////////////////////////////
// // Sticky navigation

// const sectionHeroEl = document.querySelector(".section-hero");

// const obs = new IntersectionObserver(
//   function (entries) {
//     const ent = entries[0];
//     console.log(ent);

//     if (ent.isIntersecting === false) {
//       document.body.classList.add("sticky");
//     }

//     if (ent.isIntersecting === true) {
//       document.body.classList.remove("sticky");
//     }
//   },
//   {
//     // In the viewport
//     root: null,
//     threshold: 0,
//     rootMargin: "-80px"
//   }
// );
// obs.observe(sectionHeroEl);

// ///////////////////////////////////////////////////////////
// // Fixing flexbox gap property missing in some Safari versions
// function checkFlexGap() {
//   var flex = document.createElement("div");
//   flex.style.display = "flex";
//   flex.style.flexDirection = "column";
//   flex.style.rowGap = "1px";

//   flex.appendChild(document.createElement("div"));
//   flex.appendChild(document.createElement("div"));

//   document.body.appendChild(flex);
//   var isSupported = flex.scrollHeight === 1;
//   flex.parentNode.removeChild(flex);
//   console.log(isSupported);

//   if (!isSupported) document.body.classList.add("no-flexbox-gap");
// }
// checkFlexGap();

// //***Box-Service***//
// var boxservice = document.getElementsByClassName("box-service")[0];
// var boxservicetext = document.getElementsByClassName("box-service-text")[0];
// boxservice.addEventListener("mouseover", func_zero, false);
// boxservice.addEventListener("mouseout", func_zero1, false);
// function func_zero() {
//   boxservicetext.style.color = "white";
// }
// function func_zero1() {
//   //console.log("hovered out");
//   boxservicetext.style.color = "black";
// }
// //***Box-Service***//
// var boxservice_1 = document.getElementsByClassName("box-service")[1];
// var boxservicetext_1 = document.getElementsByClassName("box-service-text")[1];
// boxservice_1.addEventListener("mouseover", func_one, false);
// boxservice_1.addEventListener("mouseout", func_one1, false);
// function func_one() {
//   boxservicetext_1.style.color = "white";
// }
// function func_one1() {
//   //console.log("hovered out");
//   boxservicetext_1.style.color = "black";
// }
// //***Box-Service***//
// var boxservice_2 = document.getElementsByClassName("box-service")[2];
// var boxservicetext_2 = document.getElementsByClassName("box-service-text")[2];
// boxservice_2.addEventListener("mouseover", func_two, false);
// boxservice_2.addEventListener("mouseout", func_two1, false);
// function func_two() {
//   boxservicetext_2.style.color = "white";
// }
// function func_two1() {
//   //console.log("hovered out");
//   boxservicetext_2.style.color = "black";
// }
// //***Box-Service***//
// var boxservice_3 = document.getElementsByClassName("box-service")[3];
// var boxservicetext_3 = document.getElementsByClassName("box-service-text")[3];
// boxservice_3.addEventListener("mouseover", func, false);
// boxservice_3.addEventListener("mouseout", func1, false);
// function func() {
//   boxservicetext_3.style.color = "white";
// }
// function func1() {
//   //console.log("hovered out");
//   boxservicetext_3.style.color = "black";
// }

// function readmore(num) {
//   var dots = document.querySelectorAll("#dots")[num];
//   var moreText = document.querySelectorAll("#more")[num];
//   var btnText = document.querySelectorAll("#myBtn")[num];

//   if (dots.style.display === "none") {
//     dots.style.display = "inline";
//     btnText.innerHTML = "Read more";
//     moreText.style.display = "none";
//   } else {
//     dots.style.display = "none";
//     btnText.innerHTML = "Read less";
//     moreText.style.display = "inline";
//   }
// }
